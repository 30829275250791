import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* merriweather-regular - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/merriweather-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v22-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
  }

  /* merriweather-700 - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/merriweather-v22-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v22-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v22-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
  }

  /* merriweather-italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/merriweather-v22-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v22-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v22-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v22-latin-italic.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v22-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v22-latin-italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }

  /* merriweather-700italic - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/merriweather-v22-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/merriweather-v22-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/merriweather-v22-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/merriweather-v22-latin-700italic.woff') format('woff'), /* Modern Browsers */
        url('/fonts/merriweather-v22-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/merriweather-v22-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
  }

  /* montserrat-regular - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* montserrat-italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/montserrat-v15-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/montserrat-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v15-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* montserrat-500 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v15-latin-500.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v15-latin-600.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('/fonts/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/fonts/montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
        url('/fonts/montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/fonts/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    background: var(--bg-color);
    color: var(--text-color);
    font-family: var(--font-sans);
    font-size: var(--fs-base);
    padding-top: 8rem;
  }

  body.noscroll {
    overflow: hidden;
  }

  body.noscroll #___gatsby {
    filter: blur(.2rem);
  }

  .inner {
    margin: auto;
    max-width: ${props => props.theme.maxWidth};
    padding: 0 ${props => props.theme.gutterSm};
    width: 100%;

    @media screen and (min-width: 990px) {
      padding: 0 ${props => props.theme.gutterLg};
    }
  }

  li,
  p,
  a,
  textarea,
  input,
  button,
  span {
    font-size: ${props => props.theme.fs3};
    font-weight: 400;
    line-height: 150%;
  }

  input[type=text],
  input[type=email],
  textarea {
    appearance: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-serif);
    color: var(--text-color);
    font-weight: 600;
    letter-spacing: -0.025rem;
    margin: 0;
  }

  .hidden-visually {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export default GlobalStyles;
