import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, useStaticQuery } from 'gatsby';

export default function CategorySidebarLink({ children, node, classes }) {
  const { equipment, attachments, forSale } = useStaticQuery(graphql`
    query {
      equipment: allPrismicEquipment {
        edges {
          node {
            data {
              category {
                uid
              }
            }
          }
        }
      }
      attachments: allPrismicAttachments {
        edges {
          node {
            data {
              category {
                uid
              }
            }
          }
        }
      }
      forSale: allPrismicForSale {
        edges {
          node {
            data {
              category {
                uid
              }
            }
          }
        }
      }
    }
  `);

  // return equipment link if category has equipment - else return attachment or forSale link
  let categorySlug = `equipment`;

  // check for equipment
  const hasEquipment = equipment.edges.filter(
    ({ node: { data } }) => data.category.uid === node.uid
  ).length;

  // check for attachments
  const hasAttachments = attachments.edges.filter(
    ({ node: { data } }) => data.category.uid === node.uid
  ).length;

  if (!hasEquipment && hasAttachments) {
    categorySlug = `attachments`;
  }

  // check for attachments
  const hasForSale = forSale.edges.filter(
    ({ node: { data } }) => data.category.uid === node.uid
  ).length;

  if (!hasEquipment && hasForSale) {
    categorySlug = `for-sale`;
  }

  return (
    <Link to={`/${node.uid}/${categorySlug}`} className={classes}>
      {children}
    </Link>
  );
}

CategorySidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  node: PropTypes.object.isRequired,
  classes: PropTypes.string,
};

CategorySidebarLink.defaultProps = {
  classes: ``,
};
