import { Component } from 'react';
import PropTypes from 'prop-types';

class ToggleAnnouncement extends Component {
  static propTypes = {
    children: PropTypes.func,
  };

  state = {
    show: false,
  };

  componentDidMount() {
    const seen = JSON.parse(sessionStorage.getItem(`seen`));

    if (!seen) {
      this.setState({ show: true });
    }
  }

  close = () => {
    sessionStorage.setItem(`seen`, true);
    this.setState({ show: false });
  };

  render() {
    const { children } = this.props;
    const { show } = this.state;
    return children({ show, close: this.close });
  }
}

export default ToggleAnnouncement;
