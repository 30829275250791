const colors = {
  bgColor: `#f9fafa`,
  black: `hsla(201, 100%, 6%, .9)`,
  blue: `#046392`,
  clearblue: `hsla(200, 95%, 29%, 0.4)`,
  clearred: `hsla(0, 99%, 38%, .5)`,
  darkblue: `#035983`,
  darkgrey: `hsla(201, 100%, 6%, .65)`,
  darkred: `#860000`,
  grey: `#a0a7aa`,
  lightblack: `hsla(201, 100%, 6%, .75)`,
  lightblue: `#1d729c`,
  lightgrey: `#e0e1e1`,
  lightred: `#cc3333`,
  offwhite: `#eff5f8`,
  red: `#c00101`,
  white: `#fefefe`,
};

const fonts = {
  font1: `'Montserrat', sans-serif`,
  font2: `'Caladea', serif`,
  fs1: `1.2rem`,
  fs2: `1.4rem`,
  fs3: `1.6rem`,
  fs4: `2rem`,
  fs5: `2.4rem`,
  fs6: `3.6rem`,
  fs7: `4.8rem`,
};

const styles = {
  bs: `0 .2rem .2rem rgba(4, 99, 146, 0.035)`,
  br: `.2rem`,
  maxWidth: `140rem`,
  gutterSm: `2rem`,
  gutterLg: `3rem`,
};

const theme = {
  ...colors,
  ...styles,
  ...fonts,
};

export default theme;
