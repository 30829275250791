import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  height: 3rem;
  width: 3rem;

  path {
    fill: ${props => props.theme.grey};
  }
`;

const MenuIcon = () => (
  <SVG className="svg-inline--fa fa-bars fa-w-14" viewBox="0 0 448 512">
    <path d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z" />
  </SVG>
);
export default MenuIcon;
