import React from 'react';
import styled from 'styled-components';
import MenuIcon from '@icons/menu';
import MobileNav from '@components/mobileNav';
import Toggle from '@components/toggle';

export default function MobileNavBtn() {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <>
          <BtnStyles
            type="button"
            aria-label="Show mobile navigation."
            className="mobile menu-btn"
            onClick={toggle}
          >
            <MenuIcon />
          </BtnStyles>
          {on && <MobileNav toggle={toggle} />}
        </>
      )}
    </Toggle>
  );
}

const BtnStyles = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 5rem;

  @media screen and (min-width: 990px) {
    display: none;
  }
`;
