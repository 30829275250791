import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import HeaderStyles from '@styles/header';
import ChevronRightIcon from '@icons/chevronRight';
import PhoneIcon from '@icons/phone';
import MobileNav from '@components/mobileNavBtn';
import CategoryModal, { CategoryModalStyles } from '@components/categoryModal';
import Toggle from '@components/toggle';
import Modal from '@components/modal';

export default function Header({ page }) {
  const { theme } = useStaticQuery(graphql`
    query headerPhone {
      theme: prismicThemeSettings {
        id
        data {
          small_logo {
            url
          }
          large_logo {
            url
            fluid(maxHeight: 50) {
              ...GatsbyPrismicImageFluid
            }
          }
          phone_number {
            text
          }
        }
      }
    }
  `);

  return (
    <HeaderStyles>
      <div className="inner">
        <a
          href={`tel:${theme.data.phone_number.text.replace(/[- )(]/g, ``)}`}
          aria-label="Give us a call!"
          className="phone mobile"
        >
          <PhoneIcon />
        </a>
        <Link to="/" id="logo">
          {/* TODO: replace with picture element */}
          <img
            src={theme.data.large_logo.url}
            alt="Dutch Rentalz Logo"
            className="desktop"
          />
          <img
            src={theme.data.small_logo.url}
            alt="Dutch Rentalz Logo"
            className="mobile"
          />
        </Link>
        <nav className="desktop">
          <Link to="/" className={page === `/` ? `active` : ``}>
            Home
          </Link>
          <Link
            to="/equipment"
            aria-label="Check out our catalog of rental equipment"
            className={
              page === `/equipment` ||
              page === `/attachments` ||
              page === `/for-sale`
                ? `active`
                : ``
            }
          >
            Equipment
          </Link>
          <Link
            to="/about-us"
            aria-label="Learn more about Dutch Rentalz"
            className={page === `/about-us` ? `active` : ``}
          >
            About Us
          </Link>
          <Link
            to="/contact-us"
            className={page === `/contact-us` ? `active` : ``}
          >
            Contact Us
          </Link>
        </nav>
        <MobileNav type="nav" />
      </div>

      {/* category nav button */}
      {page !== `/` && page !== `/about-us` && page !== `/contact-us` && (
        <CategoryModalStyles page={page}>
          <Toggle>
            {({ on, toggle }) => (
              <>
                {on && (
                  <Modal toggle={toggle}>
                    <CategoryModal toggle={toggle} />
                  </Modal>
                )}
                <div className="mobile-categories-btn-wrapper mobile">
                  <button type="button" onClick={toggle}>
                    <span>Browse by category</span>
                    <ChevronRightIcon />
                  </button>
                </div>
              </>
            )}
          </Toggle>
        </CategoryModalStyles>
      )}
    </HeaderStyles>
  );
}

Header.propTypes = {
  page: PropTypes.string,
};
