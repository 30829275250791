import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Portal from '@components/portal';
import CloseBtn from '@icons/closeBtn';

class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    toggle: PropTypes.func,
    showClose: PropTypes.bool,
  };

  static defaultProps = {
    showClose: true,
  };

  componentDidMount() {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.add(`noscroll`);
    }
  }

  componentWillUnmount() {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.remove(`noscroll`);
    }
  }

  render() {
    const { children, toggle, showClose } = this.props;
    return (
      <Portal>
        <ModalWrapper>
          <Background onClick={toggle} />
          <div className="modal-inner">
            <ModalCard>
              {showClose && (
                <CloseBtnStyles
                  type="button"
                  aria-label="Close"
                  onClick={toggle}
                >
                  <CloseBtn />
                </CloseBtnStyles>
              )}
              <div className="card-inner">{children}</div>
            </ModalCard>
          </div>
        </ModalWrapper>
      </Portal>
    );
  }
}

const ModalWrapper = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5000;

  .modal-inner {
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    background: none;
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow-y: scroll;
    width: 100vw;

    @media screen and (min-width: 768px) {
      padding: ${props => props.theme.gutterLg};
    }
  }
`;

const ModalCard = styled.div`
  background: none;
  border-radius: 0.2rem;
  padding: 3rem 3rem 10rem;
  position: relative;
  width: 100%;
  z-index: 6000;

  @media screen and (min-width: 768px) {
    padding: 3rem;
  }

  .card-inner {
    margin: auto;
    max-width: 50rem;
  }

  @media screen and (min-width: 768px) {
    background: ${props => props.theme.white};
    box-shadow: 0.2rem 0.2rem 0.6rem rgba(4, 99, 146, 0.2);
    width: 50rem;
  }
`;

const CloseBtnStyles = styled.button`
  align-items: center;
  background: none;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3rem;

  @media screen and (min-width: 768px) {
    right: 1rem;
    top: 1rem;
  }

  &:focus {
    box-shadow: 0 0 0.2rem 0.4rem ${props => props.theme.offwhite};
  }

  svg {
    left: 0.5rem;
    position: absolute;
    top: 0.5rem;
  }

  svg path {
    fill: ${props => props.theme.red};
  }
`;

const Background = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    background: rgba(255, 255, 255, 0.8);
  }
`;

export default Modal;
export { CloseBtnStyles };
