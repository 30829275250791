import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = React.createContext();

export default function SearchProvider({ children }) {
  const [search, setSearch] = useState(``);
  const [results, setResults] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        search,
        results,
        setSearch,
        setResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
