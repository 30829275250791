import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Portal from '@components/portal';
import { CloseBtnStyles } from '@components/modal';
import CloseBtn from '@icons/closeBtn';

class MobileNav extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.add(`noscroll`);
    }
  }

  componentWillUnmount() {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.remove(`noscroll`);
    }
  }

  render() {
    const { toggle } = this.props;
    return (
      <Portal>
        <MobileNavStyles>
          <Link to="/">Home</Link>
          <Link
            to="/equipment"
            aria-label="Check out our catalog of rental equipment"
          >
            Equipment
          </Link>
          <Link to="/about-us" aria-label="Learn more about Dutch Rentalz">
            About Us
          </Link>
          <Link to="/contact-us" aria-label="Send us a message!">
            Contact Us
          </Link>
          <CloseBtnStyles type="button" aria-label="Close" onClick={toggle}>
            <CloseBtn />
          </CloseBtnStyles>
        </MobileNavStyles>
      </Portal>
    );
  }
}

const MobileNavStyles = styled.nav`
  -webkit-overflow-scrolling: touch;
  animation: 0.1s ease-in-out 0s 1 slideIn;
  background: ${props => props.theme.blue};
  border-top: 0.3rem solid ${props => props.theme.darkblue};
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.7);
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0.965;
  overflow-y: auto;
  padding-top: 4rem;
  position: fixed;
  top: 0;
  transition: top 0.15s ease-in-out;
  width: 100vw;
  z-index: 107500;

  @media screen and (min-width: 1200px) {
    display: none;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  &.menu-shown {
    display: block;
  }

  a {
    color: ${props => props.theme.white};
    display: block;
    font-size: ${props => props.theme.fs4};
    margin: 0.3rem 0;
    padding: 1rem;
    position: relative;
    text-align: center;
    text-decoration: none;
  }

  .alert-badge {
    top: 1.25rem;
    right: 2rem;
  }

  button:focus {
    box-shadow: 0 0 0.2rem 0.2rem ${props => props.theme.lightblue};
  }

  button {
    height: 4rem;
    width: 4rem;
  }

  svg {
    height: 3rem;
    width: 3rem;
  }

  svg {
    left: 0.5rem;
    position: absolute;
    top: 0.5rem;
  }

  svg path {
    fill: ${props => props.theme.white};
  }
`;

export default MobileNav;
