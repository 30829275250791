import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Header from '@components/header';
import Footer from '@components/footer';
import SearchProvider from '@components/searchContext';
import ToggleAnnouncement from '@components/toggleAnnouncement';
import Announcement from '@components/announcement';
import ScrollToTop from '@components/scrollToTop';
import GlobalStyles from '@styles/global';
import theme from '@styles/theme';
import '../../static/css/variables.css';

export default function Layout({ children, page }) {
  return (
    <SearchProvider>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Header page={page} />
          <main>{children}</main>
          <Footer page={page} />
          <ToggleAnnouncement>
            {({ show, close }) =>
              show && <Announcement show={show} close={close} />
            }
          </ToggleAnnouncement>
          <ScrollToTop page={page} />
        </>
      </ThemeProvider>
    </SearchProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
};
