import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import FooterStyles from '@styles/footer';
import FacebookIcon from '@icons/facebook';
import PhoneIcon from '@icons/phone';
import map from '@images/dutchrentalz-map.webp';

export default function Footer({ page }) {
  const { theme } = useStaticQuery(graphql`
    query footer {
      theme: prismicThemeSettings {
        id
        data {
          street_address {
            text
          }
          city {
            text
          }
          state {
            text
          }
          zip_code {
            text
          }
          phone_number {
            text
          }
          google_map_directions {
            text
          }
        }
      }
    }
  `);

  return (
    <FooterStyles id="footer" page={page}>
      <div className="top">
        <div className="inner">
          <div className="contact-info">
            <p className="footer-heading address-header">Contact Information</p>
            <p>Dutch Rentalz</p>
            <p>{theme.data.street_address && theme.data.street_address.text}</p>
            <p>
              {theme.data.city && theme.data.city.text},
              {theme.data.state && theme.data.state.text}
              {` `}
              {theme.data.zip_code && theme.data.zip_code.text}
            </p>
            <a
              id="get-directions"
              href={theme.data.google_map_directions.text}
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Directions
            </a>
            <a
              id="footer-phone-number"
              href={`tel:${theme.data.phone_number.text.replace(
                /[- )(]/g,
                ``
              )}`}
            >
              <PhoneIcon />
              {theme.data.phone_number.text}
            </a>
            <p className="footer-heading social-media-header">Social Media</p>
            <a
              href="https://www.facebook.com/dutchrentalz/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Find us on Facebook"
            >
              <FacebookIcon height="30" width="30" color="#fff" />
            </a>
          </div>
          <a
            href={theme.data.google_map_directions.text}
            target="_blank"
            rel="noopener noreferrer"
            className="google-map"
          >
            <img src={map} alt="" />
          </a>
          <div className="navigation">
            <p className="footer-heading">Navigation</p>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link
                  to="/equipment"
                  aria-label="Check out our catalog of rental equipment"
                >
                  Equipment
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  aria-label="Learn more about Dutch Rentalz"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact-us" aria-label="Send us a message!">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bottom">
        <p className="copyright-info" style={{ border: `none` }}>
          Copyright &copy; Dutch Rentalz {new Date().getFullYear()}
        </p>
      </div>
    </FooterStyles>
  );
}

Footer.propTypes = {
  page: PropTypes.string,
};
