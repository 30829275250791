import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import styled from 'styled-components';
import ChevronUpIcon from '@icons/chevronUp';

export default function ScrollContainer(props) {
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    if (typeof document !== `undefined` && window.innerWidth <= 1200) {
      Events.scrollEvent.register(`begin`);
      Events.scrollEvent.register(`end`);
      scrollSpy.update();

      window.addEventListener(
        `scroll`,
        () => {
          setScrollPos(window.scrollY || window.pageYOffset);
        },
        { passive: true }
      );
    }
  }, []);

  if (scrollPos > 250) {
    return <ScrollToTop {...props} />;
  }

  return null;
}

function ScrollToTop({ page }) {
  const scrollToTop = () => scroll.scrollToTop({ duration: 100 });

  return (
    <ScrollToTopStyles
      type="button"
      onClick={scrollToTop}
      aria-label="Scroll to the top of the page."
      page={page}
    >
      <ChevronUpIcon />
    </ScrollToTopStyles>
  );
}

ScrollToTop.propTypes = {
  page: PropTypes.string.isRequired,
};

const ScrollToTopStyles = styled.button`
  align-items: center;
  background: ${props => props.theme.white};
  border-radius: 50%;
  border: none;
  bottom: ${({ page }) => {
    if (
      page === `/equipment` ||
      page === `/attachments` ||
      page === `/for-sale` ||
      page === `/search`
    ) {
      return `8rem`;
    }

    return `2rem`;
  }};
  display: flex;
  height: 4rem;
  opacity: 0.8;
  outline: none;
  padding: 0;
  position: fixed;
  right: 2rem;
  width: 4rem;
  z-index: 9999;

  @media screen and (min-width: 1200px) {
    display: none;
  }

  svg {
    fill: ${props => props.theme.blue};
    opacity: 0.8;
    transform: scale(1.05);

    @media screen and (min-width: 768px) {
      bottom: 1rem;
    }
  }
`;
