import styled from 'styled-components';

const HeaderStyles = styled.header`
  background: var(--white);
  border-bottom: 0.1rem solid var(--offwhite);
  height: 8rem;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;

  .inner {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: space-between;
    padding: 0 ${props => props.theme.gutterSm};

    @media screen and (min-width: 990px) {
      padding: 0 ${props => props.theme.gutterLg};
    }
  }

  .phone.mobile {
    border-radius: 50%;
    height: 5rem;
    padding: 1.375rem;
    width: 5rem;

    @media screen and (min-width: 768px) {
      display: none;
    }

    svg {
      height: 2.25rem;
      width: 2.25rem;

      path {
        fill: ${props => props.theme.grey};
      }
    }
  }

  #logo,
  #logo img {
    width: auto;
  }

  #logo {
    height: 7rem;

    @media screen and (min-width: 768px) {
      height: 6rem;
    }
  }

  #logo .mobile {
    height: 7rem;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  #logo .desktop {
    display: none;
    height: 6rem;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  nav.desktop {
    display: none;

    @media screen and (min-width: 990px) {
      display: block;
    }
  }

  nav a {
    border-radius: ${props => props.theme.br};
    color: var(--black);
    padding: 1rem 1.5rem;
    text-decoration: none;
  }

  nav a:not(:last-child) {
    margin-right: 2rem;
  }

  nav a:not(.active):hover {
    color: ${props => props.theme.blue};
  }

  nav a.active {
    color: ${props => props.theme.blue};
    font-weight: 500;
  }
`;

export default HeaderStyles;
