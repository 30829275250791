import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import CloseBtn from '@icons/closeBtn';
import Portal from '@components/portal';
import Modal, { CloseBtnStyles } from '@components/modal';

class Announcement extends Component {
  static propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
  };

  componentWillUnmount() {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.remove(`noscroll`);
    }
  }

  onRender = () => {
    if (typeof document !== `undefined`) {
      document.querySelector(`body`).classList.add(`noscroll`);
    }
  };

  render() {
    const { show, close } = this.props;

    if (!show) return null;

    return (
      <StaticQuery
        query={graphql`
          query getAnnouncement {
            announcement: prismicAnnouncement(
              data: { active: { eq: "Active" } }
            ) {
              data {
                title {
                  html
                  text
                }
                copy {
                  html
                  text
                }
                show_image
                image {
                  alt
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        `}
        render={({ announcement }) => {
          if (!announcement) return null;

          const { title, copy, image, show_image } = announcement.data;
          const showImage = show_image.toLowerCase() === `yes`;

          this.onRender();

          return (
            <Portal>
              <Modal showClose={false} toggle={close}>
                <AnnouncementStyles>
                  <h2>{title.text}</h2>
                  <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                  {image?.fluid && showImage && (
                    <div className="image-wrapper">
                      <Img fluid={image.fluid} alt={title.text} />
                    </div>
                  )}
                  <CloseBtnAlt>
                    <button type="button" onClick={close} aria-label="Close">
                      Close
                    </button>
                  </CloseBtnAlt>
                </AnnouncementStyles>
                <CloseBtnStyles
                  type="button"
                  aria-label="Close"
                  onClick={close}
                >
                  <CloseBtn />
                </CloseBtnStyles>
              </Modal>
            </Portal>
          );
        }}
      />
    );
  }
}

const AnnouncementStyles = styled.div`
  button {
    cursor: pointer;
  }

  h2 {
    color: ${props => props.theme.blue};
  }

  p {
    margin-bottom: 0;
  }

  .image-wrapper {
    margin-top: 1rem;
  }
`;

export const CloseBtnAlt = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  button {
    background: ${props => props.theme.red};
    border-radius: 0.2rem;
    border: none;
    color: ${props => props.theme.white};
    cursor: pointer;
    height: 4rem;
    margin: 2rem auto;
    outline: none;
    padding: 0.5rem 1rem;
    width: 12rem;

    &:hover {
      background: ${props => props.theme.darkred};
    }

    &:focus {
      background: ${props => props.theme.lightred};
      box-shadow: 0 0 0.2rem 0.4rem ${props => props.theme.clearred};
    }
  }
`;

export default Announcement;
